<template>
  <div class="px-10">
    <div align="center">
      <v-col cols="10">
        <v-progress-linear :value="valueDeterminate" height="30">
          <strong>ตั้งค่าสำเร็จ {{ Math.ceil(valueDeterminate) }}%</strong>
        </v-progress-linear>
      </v-col>
    </div>
    <v-row class="mb-7"
      ><v-col cols="1"></v-col>
      <v-col cols="2"
        ><div class="text-h5 primary--text">1. ตั้งค่าสาขา</div></v-col
      >
      <v-col cols="4"
        ><div class="text-h6">2. เปิดใช้งาน พ.ร.บ./ประกันภัย</div></v-col
      >
      <v-col cols="2"><div class="text-h6">3. ตั้งค่าธุรกิจ</div></v-col>
      <!-- <v-col cols="2"><div class="text-h6">4. ตั้งค่าเอกสาร</div></v-col> -->
      <v-col cols="2"><div class="text-h6">4. ราคาสินค้า</div></v-col
      ><v-col cols="1"></v-col>
    </v-row>
    <div align="center" class="text-h5 mb-4 red--text mt-14">
      โปรดตั้งค่ารายละเอียดต่างๆ บางช่องสามารถเว้นว่างไว้ได้
    </div>
    <div class="text-h4 primary--text mb-5 mt-14">
      1. ตั้งค่าข้อมูลที่จะแสดงในใบเสร็จรับเงิน
    </div>
    <v-row>
      <v-col cols class="pl-15">
        <!-- <v-text-field
          name="branch_name"
          label="ชื่อสาขา"
          outlined
          dense
          v-model="form.branch_name"
          :error-messages="$v.form.branch_name.$error ? $error_text : ''"
        /> -->
        <v-text-field
          name="store_name"
          label="ชื่อร้าน"
          outlined
          dense
          v-model="form.store_name"
          :error-messages="$v.form.store_name.$error ? $error_text : ''"
        />
        <v-text-field
          name=""
          label="ชื่อร้านที่จะแสดงบนหัวใบเสร็จ"
          outlined
          dense
          v-model="form.name_for_receipt"
          :error-messages="$v.form.name_for_receipt.$error ? $error_text : ''"
        />
        <v-text-field
          name="phone_number"
          label="เบอร์ติดต่อ"
          outlined
          dense
          v-model="form.phone_number"
          :error-messages="$v.form.phone_number.$error ? $error_text : ''"
        />

        <!-- <v-text-field
          name=""
          label="ที่อยู่ (ไม่จำเป็น)"
          outlined
          dense
          @click="
            (dialogAddressData = form.location),
              (dialogAddress = true),
              (dialogAddressKey = 'location')
          "
          :value="`${form.location.address} ${form.location.sub_district} ${form.location.district} ${form.location.province} ${form.location.postal_code}`"
        /> -->
        <v-text-field
          name=""
          label="เวลาทำการ (ควรใส่)"
          value="คลิกเพื่อเปิดดู"
          outlined
          dense
          @click="
            (dialogBusinessHoursData = form), (dialogBusinessHoursUpdate = true)
          "
        />
        <v-row class="ml-2 mb-8 mt-2">
          <span>ใส่ธนาคารที่ไว้รับเงินโอนจากลูกค้า ตอนนี้มี {{ form.bank_account.length }} ธนาคาร</span>
          <v-btn
            class="ml-5"
            color="primary"
            small
            outlined
            @click="(dialogData = form.location), (dialogUpdate = true)"
            >เพิ่มธนาคาร</v-btn
          >
          </v-row
        >
      </v-col>
      <v-col cols offset="1">
        <!-- <div class="mb-5">ตำแหน่งที่ตั้ง (ไม่จำเป็น)</div>
        <v-col>
          <v-row>
            <v-text-field
              name="lat"
              label="ละติจูด"
              outlined
              dense
              v-model="form.geopoint.lat"
            />
            &nbsp; &nbsp;
            <v-text-field
              name="lng"
              label="ลองจิจูด"
              outlined
              dense
              v-model="form.geopoint.lng"
            />
          </v-row>
        </v-col> -->
        <v-col>
           <v-text-field
          name="line_id"
          label="Line ID ร้าน (ไม่จำเป็น)"
          outlined
          dense
          v-model="form.line_id"
        />
        <v-text-field
          name="facebook"
          label="Facebook ร้าน (ไม่จำเป็น)"
          outlined
          dense
          v-model="form.facebook"
        />
        </v-col>
        <!-- <v-col>
          <v-row>
            <div class="mt-1">เป็นนิติบุคคล ?</div>

            <v-radio-group class="mt-0 ml-10" row v-model="legalEntity1">
              <v-radio name="" label="เป็น" :value="true"></v-radio>
              <v-radio name="" label="ไม่เป็น" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <div v-if="legalEntity1">
          <v-text-field
            name="company_branch"
            label="สาขาบริษัท"
            outlined
            dense
            v-model="form.company_branch"
          />
          <v-text-field
            name="taxpayer_number"
            label="เลขประจำตัวผู้เสียภาษี"
            outlined
            dense
            v-model="form.taxpayer_number"
            :error-messages="$v.form.taxpayer_number.$error ? $error_text : ''"
          />
          <v-text-field
            name="email"
            label="E - mail"
            outlined
            dense
            v-model="form.email"
            :error-messages="$v.form.email.$error ? $error_text : ''"
          />
          <v-col>
            <v-row>
              <div class="mt-1">ภาษีมูลค่าเพิ่ม</div>

              <v-radio-group
                class="mt-0 ml-10"
                row
                v-model="form.vat_registration"
              >
                <v-radio name="" label="จดภาษี" :value="true"></v-radio>
                <v-radio name="" label="ไม่จดภาษี" :value="false"></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
          <br />
        </div> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <!-- <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ยกเลิก</v-btn
        > -->
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          ถัดไป
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="600" v-model="dialogUpdate">
      <BankAccount
        :item="form.bank_account"
        :delete_bank_account="form.delete_bank_account"
        @onClose="dialogUpdate = false"
        @onChange="onChangeBankAccount"
      />
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      max-width="1200"
      v-model="dialogBusinessHoursUpdate"
    >
      <BusinessHours
        :item="form.open_hours"
        @onClose="dialogBusinessHoursUpdate = false"
        @onChange="(val) => (form.open_hours = val)"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
import BankAccount from '@/views/pages/setting/business-branch/BankAccount.vue';
import BusinessHours from '@/views/pages/setting/business-branch/BusinessHours.vue';
import {
  required,
  checked,
  email,
  maxLength,
  minLength,
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators';
import AddressDetail from './AddressDetail.vue';
import uploadImage from '@/components/uploadImage.vue';

export default {
  components: {
    BankAccount,
    BusinessHours,
    AddressDetail,
    uploadImage,
  },
  data: () => ({
    valueDeterminate: 20,
    legalEntity1: false,
    legalEntity2: false,
    readMore: {},
    form: {
      branch_id: null,
      setup_wizard: 0,
      bank_account: [],
      branch_name: '',
      company_branch: '',
      // company_group_id: "",
      document_setting: {
        credit_note: { header: '', note: '' },
        debit_note: { header: '', note: '' },
        invoice: { header: '', note: '' },
        quotation: { header: '', note: '' },
        receipt: { header: '', note: '' },
        tax_invoice: { header: '', note: '' },
      },
      email: '',
      facebook: '',
      geopoint: { lat: '', lng: '' },
      is_split_bill: true,
      line_id: '',
      location: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
      logo_url: '',
      name_for_receipt: '',
      open_hours: {
        friday: { open: false, time: [{ end: '', start: '' }] },
        monday: { open: false, time: [{ end: '', start: '' }] },
        saturday: { open: false, time: [{ end: '', start: '' }] },
        sunday: { open: false, time: [{ end: '', start: '' }] },
        thursday: { open: false, time: [{ end: '', start: '' }] },
        tuesday: { open: false, time: [{ end: '', start: '' }] },
        wednesday: { open: false, time: [{ end: '', start: '' }] },
      },
      phone_number: '',
      setting: {
        print_one_page: '',
        enable_product_merging: false,
        receipt_printing_format: 0,
        domain_for_qr: '',
        alert_onselect_cartype2: '',
        ban_car_province: [],
        car_tax_scrap: 0,
        cost_extension_car_tax: 0,
        cost_extension_motorbike_tax: 0,
        document_pickup_date: 0,
        document_pickup_time: '',
        merging_subcategory: [],
        ges_inspect_alert: false,
        letter_alert: false,
        reduce_bill_scrap: false,
        sms_alert: false,
        starting_province: '',
        tel_alert: false,
        third_insurance_tax_scrap: 0,
        CT1_3rdinsurance_express: '',
        CT2_3rdinsurance_express: '',
        CT3_3rdinsurance_express: '',
        CT4_3rdinsurance_express: '',
        other_3rdinsurance_express: '',
        qr_tracking: false,
        domain_for_qr: '',
      },
      split_bill_category: [],
      split_bill_data: {
        company_branch: '',
        email: '',
        facebook: '',
        line_id: '',
        location: {
          address: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: '',
        },
        logo_url: '',
        name: '',
        taxpayer_number: '',
        phone_number: '',
        vat_registration: false,
        is_same_as_above: false,
      },
      store_name: '',
      taxpayer_number: '',
      vat_registration: false,
    },

    dialogUpdate: false,
    dialogData: null,
    dialogBusinessHoursUpdate: false,
    dialogBusinessHoursData: null,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },

    selected: [],
    loading: false,
    categories: [],
  }),
  validations: function () {
    return {
      form: {
        branch_name: { required },
        store_name: { required },
        email: { email },
        name_for_receipt: { required },
        phone_number: { required },
        taxpayer_number: {
          numeric,
          minLength: minLength(13),
          maxLength: maxLength(13),
        },
        // company_branch: {
        //   required: requiredIf(function() {
        //     return this.form.is_split_bill;
        //   })
        // },
        // email: {
        //   email: requiredIf(function() {
        //     return this.form.is_split_bill;
        //   })
        // },
        // location: {
        //   address: {
        //     required: requiredIf(function() {
        //       return this.form.is_split_bill;
        //     })
        //   },
        //   district: {
        //     required: requiredIf(function() {
        //       return this.form.is_split_bill;
        //     })
        //   },
        //   postal_code: {
        //     required: requiredIf(function() {
        //       return this.form.is_split_bill;
        //     })
        //   },
        //   province: {
        //     required: requiredIf(function() {
        //       return this.form.is_split_bill;
        //     })
        //   },
        //   sub_district: {
        //     required: requiredIf(function() {
        //       return this.form.is_split_bill;
        //     })
        //   }
        // },
        taxpayer_number: {
          numeric,
          minLength: minLength(13),
          maxLength: maxLength(13),
        },
      },
    };
  },
  created() {
    console.log(this.form);
    this.branch_id = this.$route.params.id;
    this.getCategory();
    this.getData();
    this.getCheck();
  },
  methods: {
    async getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          let check = res.result;
          if (check == 0) {
            check = 1;
          }
          console.log(check);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    showDetail() {
      window.open(
        'https://manual.permtang.com/documentation/setting/2-business-branch/'
      );
    },

    getCategory() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$store.state.selected_branch._id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/sub_category/get_sub_category`, body)
        .then(async (res) => {
          this.categories = res.result;
        });
    },
    getData() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/wizard/get_by_branch_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          if (this.form.taxpayer_number) {
            this.legalEntity1 = true;
          } 
          if (this.form.split_bill_data_taxpayer_number) {
            this.legalEntity2 = true;
          } 
          this.form.delete_bank_account = [];
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      this.form.branch_name = this.form.store_name;
      if (this.$v.form.$invalid) return;
      this.loading = true;
      if (this.form.setup_wizard > 1) {
        this.form.setup_wizard = this.form.setup_wizard;
      } else {
        this.form.setup_wizard = 2;
      }
      this.form = { branch_id: this.form._id, ...this.form };
      let body = {
        token: this.$jwt.sign(this.form, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/setup_wizard1`, body)
        .then((res) => {
          this.$router.push({
            name: 'setup-insurance',
            params: { id: res.result },
          });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      console.log(this.form);
      console.log('save()');
      this.loading = false;
    },
    async close() {
      let fill = {
        branch_id: this.form._id,
        setup_wizard: 1,
      };
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/set_setup_wizard`, body)
        .then((res) => {
          this.$router.push({
            name: 'setting-business-branch',
          });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      console.log(this.form);
    },
    onChangeBankAccount(bankAccount, deleteList) {
      this.form.bank_account = bankAccount;
      if (this.isUpdate) this.form.delete_bank_account = deleteList;
    },
    onSubmitAddress(val) {
      if (this.dialogAddressKey == 'split_bill_data.location')
        this.form.split_bill_data.location = val;
      else {
        this.form[this.dialogAddressKey] = val;
        if (this.form.split_bill_data.is_same_as_above)
          this.form.split_bill_data.location = { ...val };
      }
      this.dialogAddress = false;
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
